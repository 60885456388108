/*jslint browser: true, nomen: true */
/*global $ */
'use strict';

function PageTimer() {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10)/100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      console && console.info && console.info("Client loaded in " + self.getLoadTime() + 's');
    });
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text(' | LT: ' + self.getLoadTime() + 's');
    });
  }
}


function StickyFooter ($wrap, $footer) {
  var $window = $(window);
  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.update = function () {
    this.updateWrapperCSS();

    return this;
  }
}


/*
 Ensure all external links load as new window/tabs:
 */

 var App;

 function ExternalLinkHandler () {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {
    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      var isInternalLink = false;

      for (var i = 0; i < self.matchInternalLink.length; i++) {
        var regex = self.matchInternalLink[i];
        if (href.match(regex)) {
          isInternalLink = true;
        }
      }

      if (!isInternalLink) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings () {
  this.bindTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };
  this.bindPopovers = function () {
    $('[data-toggle="popover"]').popover();
  };

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
    });
  };

  this.closeModal = function(){
    $('.close').on('click',function(){
      console.log('hide');
      $('#home-modal').css('display','none');
      // $.cookie('modal', 'modal-seen');
      Cookies.set('modal', 'modal-seen');
    });
    $('.apply-btn').on('click',function(){
      console.log('hide');
      $('#home-modal').css('display','none');
      // $.cookie('modal', 'modal-seen');
      Cookies.set('modal', 'modal-seen');
    });
  };
  
  this.bindSubmittingButtons = function () {
    $(document).on('submit', function () {
      var submitText = $(this).find('[data-submit-text]');
      var submittingText = $(this).find('[data-submitting-text]');
      var btn = submitText.closest('button');
      submittingText.removeClass('hide');
      submitText.hide();
      btn.prop('disabled', true);
    });
  };
}

function Notifications () {
  var wrapper = $('#notifications-wrapper');
  var div = $('#notifications');
  var wrapperTop = wrapper.offset().top;

  this.bindOnScroll = function () {
    wrapper.height(wrapper.height());
    $(window).on('scroll', function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > wrapperTop) {
        div.addClass('fixed');
      } else {
        div.removeClass('fixed');
      }
    })
  };


  this.bindCloseButton = function () {
    $(document).on('click', '[data-toggle="remove"]', function (e) {
      e.preventDefault();
      var notification = $(this).closest('.notification');
      notification.fadeOut(250, function () {
        wrapper.height('auto');
        wrapper.height(wrapper.height());

      });
    });
  };

  this.init = function () {

    this.bindOnScroll();
    this.bindCloseButton();
  };
}

$(function () {

  (new StickyFooter($('#container'), $('#footer'))).update().bindOnResize();

  (new ExternalLinkHandler).addTargetAttribute($('body'));

  (new UIBindings).bindTooltips();

  (new UIBindings).closeModal();

  (new UIBindings).bindPopovers();

  (new UIBindings).bindSharing();

  (new PageTimer).logToConsole();

  (new UIBindings).bindSubmittingButtons();

  (new Notifications).init();
  
  // (new UIBindings).bindMasonary();

  $(".picker").birthdaypicker({
    futureDates: true,
    maxYear: 2020,
    maxAge: 75,
    defaultDate: "10-17-1980",
    fieldID   : "birthDay"
  });

   if(window.location.href.indexOf("#message") > -1) {
    $( ".alert" ).addClass( "show" );
    $( ".alert" ).removeClass( "hide" );
  }else{
    $( ".alert" ).addClass( "hide" );
    $( ".alert" ).removeClass( "show" );
  }

   var wow = new WOW({
    boxClass:     'wow',
    animateClass: 'animated',
    offset:       100
  });

   wow.init();

   $('.selectpicker').selectpicker({
    nonSelectedText:'My Calendar',


  });

  $("#gallery").unitegallery({
  slider_scale_mode: "fit", 
  slider_scale_mode_media: "fit",
  gallery_skin:"default"
}); 

  $('#resultsCarousel').carousel();

   $('#confirmOptions').on('click', function () {
    var brands = $('.selectpicker').val();  
    JSON.stringify(brands);
    window.location.href = "/calendar/month/?cals=" + brands;
  });

});
